(function ($) {

    var root = location.protocol + '//' + location.host;

    $('.features1').backstretch(root + '/app/themes/sage-passjobs/dist/images/selectie.jpg');
    $('.features2').backstretch(root + '/app/themes/sage-passjobs/dist/images/outplacement.jpg');
    $('.features3').backstretch(root + '/app/themes/sage-passjobs/dist/images/hr-intelligence.jpg');
    $('.banner').backstretch(root + '/app/themes/sage-passjobs/dist/images/home-banner.jpg');
    $('.homeBlog').backstretch(root + '/app/themes/sage-passjobs/dist/images/home-blog.jpg');

    // Load Events
    $(document).ready(function () {


        function init(){
            fixHeader();
            navToggle();
            smoothAnchor();
            vacancyFilter();
            initSlick();
            blogThumbHeight();
            sameHeight();
            initHandlers();
        }

        //Fixed Header
        function fixHeader(){
            $('.header').scrollToFixed();
        }

        //Mobile Menu
        function navToggle(){
            $('.navIcon').click(function () {
                $('header nav.menu').stop().slideToggle(300);
            });
        }

        // Anchor links smooth
        function smoothAnchor(){
            $('a[href*="#"]:not([href="#"])').click(function() {
                if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                    if (target.length) {
                        $('html, body').animate({
                            scrollTop: target.offset().top - 50
                        }, 700);
                        return false;
                    }
                }
            });
        }

        // Vacatures filter
        function vacancyFilter(){
            if($('.mix-container').length){
                var mixer = mixitup('.mix-container', {
                    controls: {
                        toggleLogic: 'or'
                    },
                    selectors: {
                        target: '.tableRow'
                    },
                    animation: {
                        effects: 'fade translateZ(-100px)',
                        duration: 20
                    },
                    callbacks: {
                        onMixEnd: function(state) {
                            console.log(state);
                            $('.mix-container .dummy').remove();
                            $('.mix-container .tableRow:hidden').after($('<li class="dummy"></li>'));
                        }
                    }
                });
            }
        }

        //Blog Slider
        function initSlick(){
            $('.blogSlider').slick({
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 3,
                responsive: [
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        }

        // Blog thumbnail heights
        function blogThumbHeight(){
            $('.blogListImage').each(function(){
                $(this).height($(this).next('.blogListDetails'));
            });
        }

        function sameHeight(){

            if ($(window).width() > 991) {
                $('.same-height, .logoWidget, .homeBlogItem').matchHeight();

            }

            $('.vacancy-block a').matchHeight({
                byRow: false
            });
        }

        function initHandlers(){
            $(window).on('resize', sameHeight());

        }

        init();

    });

})(jQuery); // Fully reference jQuery after this point.
